<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Post / News

        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="UpdateBlogFormvalidate">
         <b-modal id="image-properties-modal-post" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="UpdateBlogForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Show In"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :clearable="false"
                  :options="showList"
                  :reduce="(val) => val.id"
                  @input="getNews"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Sub Title"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Sub Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="UpdateBlogForm.subtitle"
                  @input="removeSpace($event)"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTube Link"
                rules="required"
              >
                <b-form-input
                  id="videor_link"
                  v-model="UpdateBlogForm.slug"
                  @input="removeSpace($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>

                <p v-if="UpdateBlogForm.type==1"> link after creation:{{ `https://lpcentre.prokoders.space/articles/${UpdateBlogForm.slug}` }}</p>
                <p v-else> link after creation:{{ `https://lpcentre.prokoders.space/news/${UpdateBlogForm.slug}` }}</p>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="`https://lpcentre.prokoders.space/${UpdateBlogForm.tab_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage
                            UpdateBlogForm.tab_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="UpdateBlogForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="UpdateBlogForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="`https://lpcentre.prokoders.space/${UpdateBlogForm.header_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                            UpdateBlogForm.header_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="UpdateBlogForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="UpdateBlogForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
              <div class="border rounded p-2">
              <h4 class="mb-1">
                Og image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE3"

                      :src="UpdateBlogForm.og_image ? `https://lpcentre.prokoders.space/${UpdateBlogForm.og_image} ` : 'https://www.lpcentre.prokoders.space/images/no_image.png'"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refInputE3.src = Tabimage
                            UpdateBlogForm.og_image=''"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <b-form-file
                          ref="refInputE3"
                          v-model="UpdateBlogForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col md="6" v-if="UpdateBlogForm.type==1">
            <b-form-group
              label="Article Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(UpdateBlogForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" v-if="UpdateBlogForm.type==1">
            <b-form-group
              label="Related Course"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.related_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  multiple
                  :reduce="(val) => val.id"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
             
              label-for="blog-edit-course"
              class="mb-2"
            >
     
            <label v-if="UpdateBlogForm.type==1"> Related Article</label>
              <label v-else> Related News</label>
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
      
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.related_posts"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  multiple
                  :options="articleByCategoryList"
                  :reduce="(val) => val.id"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Details"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Details"
                rules="required"
              >
                <quill-editor
                  ref="detailsEditor"
                  v-model="UpdateBlogForm.details"
                  :options="getEditorOptions('detailsEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const getCourse = ref(false)
    const coursesList = ref([])
    const articleByCategoryList = ref([])
    const { proxy } = getCurrentInstance()
    const { route } = useRouter()
    const { id } = route.value.params
    const Form = ref({
      title: '',
      slug: '',
      tab_image: [],
      header_image: [],
      alt_tab_image: '',
      alt_header_image: '',
      type: '',
      subtitle: '',
      related_courses: [],
      related_posts: [],
      category_id: '',
    })
    const UpdateBlogForm = ref(Form)
    const getNews = () => {
      if (UpdateBlogForm.value.type == 2) {
        UpdateBlogForm.value.course = ''
        UpdateBlogForm.value.category = null
        UpdateBlogForm.value.related_article = ''
        UpdateBlogForm.value.related_posts = []
        store
          .dispatch('blog/getAllNews')
          .then(response => {
            articleByCategoryList.value = response.data.data
          })
      }
    }
  
    const getCoursesByCategory = id => {
      getCourse.value = true
      const { type } = UpdateBlogForm.value
      store
        .dispatch('categories/getCoursesByCategory', {
          ' category_id': id,
        })
        .then(response => {
          response.data.data.forEach(el => {
            if (el.online == 1) {
              coursesList.value.push({
                id: el.id,
                name: `${el.name} Online`,
                link_id: el.link_id,
              })
            } else {
              coursesList.value.push({
                id: el.id,
                name: el.name,
                link_id: el.link_id,
              })
            }
          })
        })
      if (type == 1) {
        store.dispatch('categories/getCategoryByArticle', { id, type })
          .then(response => {
            articleByCategoryList.value = response.data.data
          })
      }
    }
  
    store.dispatch('blog/Getblog', { id }).then(response => {
      coursesList.value = []
      Form.value = response?.data.data

      store.commit('blog/GET_BLOG', response?.data.data)

      Form.value.related_courses = JSON.parse(response?.data.data.related_courses)
      Form.value.related_posts = JSON.parse(response?.data.data.related_posts)
      const CategoryId = response?.data.data.category_id
      getCoursesByCategory(CategoryId)
      if (Form.value.type == 2) {
        store
          .dispatch('blog/getAllNews')
          .then(response => {
            articleByCategoryList.value = response.data.data
          })
      }
  
      store
        .dispatch('categories/getCoursesByCategory', {
          ' category_id': CategoryId,
        })
        .then(response => {
          response.data.data.forEach(el => {
            if (el.online == 1) {
              coursesList.value.push({
                id: el.id,
                name: `${el.name} Online`,
                link_id: el.link_id,
              })
            } else {
              coursesList.value.push({
                id: el.id,
                name: el.name,
                link_id: el.link_id,
              })
            }
          })
        })
    })
    const removeSpace = target => {
      UpdateBlogForm.slug = target // LowerCase
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    store.dispatch('categories/CategoryList')
      .then(response => {
        categoriesList.value = response.data
      })

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const showList = ref([{
      id: 2,
      label: 'News',
    },
    {
      id: 1,
      label: 'Articles',
    },
    ])
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )

        const refInputE3 = ref(null)
    const refPreviewE3 = ref(null)
    const UpdateBlogFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      UpdateBlogFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('title', UpdateBlogForm.value.title)
          formData.append('subtitle', UpdateBlogForm.value.subtitle)
          formData.append('slug', UpdateBlogForm.value.slug)
                  formData.append('og_image', UpdateBlogForm.value.og_image)
          formData.append('tab_image', UpdateBlogForm.value.tab_image)
          formData.append('header_image', UpdateBlogForm.value.header_image)
          formData.append('alt_tab_image', UpdateBlogForm.value.alt_tab_image)
          formData.append(' alt_header_image', UpdateBlogForm.value.alt_header_image)
          formData.append('type', UpdateBlogForm.value.type)
          formData.append('subtitle', UpdateBlogForm.value.subtitle)
          formData.append('details', UpdateBlogForm.value.details)
          if (UpdateBlogForm.value.category_id) {
            formData.append('category_id', UpdateBlogForm.value.category_id)
          }
         
          formData.append('_method', 'put')
          formData.append(
            'related_courses',
            JSON.stringify(UpdateBlogForm.value.related_courses),
          )
          formData.append(
            'related_posts',
            JSON.stringify(UpdateBlogForm.value.related_posts),
          )

          store.dispatch('blog/Updateblog', { id, formData })
            .then(response => {
              Vue.swal({
                title: ' Updated ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal-post')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
      const range = editorSelection || editor.getSelection()
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties

      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-properties-modal-post')
    }

  const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
    })

    return {
      getNews,
      refInputEl,
      id,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
       inputImageRendererTab3,
        refInputE3,
      refPreviewE3,
      Tabimage,
      UpdateBlogForm,
      inputImageRenderer,
      getCoursesByCategory,
      UpdateBlogFormvalidate,
      categoriesList,
      articleByCategoryList,
      Form,
      quillEditor,
      showList,
      getCourse,
      save,
      removeSpace,
      required,
      email,
      coursesList,
       imageHandler,
      insertImage,
      imageProperties,
      linkHandler,
      getEditorOptions,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor,
    vSelect,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>

<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
.quill-editor {
  margin-left: 1px;
  width: 100%;
}
</style>
